import React from 'react';
import './App.css';
import Navbar from './layout/navbar/Navbar';
import Banner from './pages/Banner';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Footer from './layout/footer/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ServicePage from './pages/ServicePage';





function App() {
  return (
    <div className='App'>
      <Router >
        <Navbar />
        <Routes>
          <Route path="/" element={<Banner />} />
          {/* <Route path="/experts" element={<Experts />} />
          <Route path="/print" element={<Print />} />
          <Route path="/advertising" element={<Advertising />} />
          <Route path="/network" element={<Network />} /> */}
          <Route path="/service/:serviceName" element={<ServicePage />} />

        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
