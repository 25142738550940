import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Banner.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Text from '../components/Text';
import Pictures from '../components/Pictures';
import Loading from '../layout/loader/Loading';
const baseURL = "https://adminsn.laratest-app.com/api/intros";


const Banner = () => {
  const [bannerData, setBannerData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(baseURL)
      .then((response) => {
        const bannerInfo = response.data.intros;
        setBannerData(bannerInfo);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching banner data:', error);
        setLoading(false);
      });
  }, []);
  if (loading) {
    return <Loading />;
  }

  if (!bannerData) return null;

  return (
    <div className='container-fluid'>
      <div className='row row1'>
        <div className='home col-md-6'>
          <video autoPlay loop muted className="video-fluid">
            <source src={`https://adminsn.laratest-app.com/storage/${bannerData.animation}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className='col-md-6 d-flex align-items-center headerContainer'>
          <h1>{bannerData.title}</h1>
        </div>
      </div>
      <Text />
      <hr />
      <Pictures />
    </div>
  )
}

export default Banner;
