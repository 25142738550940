import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CatPictures.css';

const baseURL = "https://adminsn.laratest-app.com/api/services";





function CatPictures({ serviceId }) {
    const [serviceData, setServiceData] = useState([]);

    useEffect(() => {
        axios.get(baseURL)
            .then(response => {
                setServiceData(response.data.services);
            })
            .catch(error => console.error('Error fetching service data:', error));
    }, []);

    const renderServiceImages = () => {
        const selectedService = serviceData.find(service => service.id === serviceId);
        if (!selectedService || !selectedService.service_images) return null;

        return (
            <div className="row row4">
                {selectedService.service_images.map((image) => (
                    <div key={image.id} className=" network col-lg-3 col-md-4 col-sm-6 mb-3">
                        <img src={`https://adminsn.laratest-app.com/storage/${image.image}`} className="img-fluid" alt="" />
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div>
            {renderServiceImages()}
        </div>
    );
}


export default CatPictures;
