import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './CatText.css';

const baseURL = "https://adminsn.laratest-app.com/api/services";


const CatText = () => {
    // const setServiceData = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const { serviceName } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(baseURL);
                const services = response.data.services;
                // setServiceData(services);

                const matchedService = services.find(service => service.title.toLowerCase() === serviceName.toLowerCase());
                setSelectedService(matchedService);
            } catch (error) {
                console.error('Error fetching service data: ', error);
            }
        };

        fetchData();
    }, [serviceName]);

    if (!selectedService) return null;

    return (
        <div className='row row2'>
            <div className='col-12 intro'>
                <p className="text-justify desc_of_service">
                    {selectedService.long_description}
                </p>
            </div>
        </div>
    );
}

export default CatText;
