import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CatText from '../components/CatText';
import CatPictures from '../components/CatPictures';
import axios from 'axios';
import Loading from '../layout/loader/Loading';



function ServicePage() {
    const { serviceName } = useParams();
    const [service, setService] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`https://adminsn.laratest-app.com/api/services`)
            .then(response => {
                const matchedService = response.data.services.find(s => s.title.toLowerCase() === serviceName.toLowerCase());
                setService(matchedService);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching service data:', error);
                setLoading(false);
            });
    }, [serviceName]);
    if (loading) {
        return <Loading />;
    }
    if (!service) return null;
    return (
        <div>

            <CatPictures serviceId={service.id} />
            <hr />
            <CatText />
        </div>
    );
}


export default ServicePage;
