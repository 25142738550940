import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = "https://adminsn.laratest-app.com/api/intros";

const Text = () => {

    const [textData, setTextData] = useState(null);

    useEffect(() => {
        axios.get(baseURL)
            .then((response) => {
                const textInfo = response.data.intros;
                setTextData(textInfo);
            })
            .catch((error) => {
                console.error('Error fetching about data:', error);
            });
    }, []);

    if (!textData) return null;
    return (

        <div className='row row2'>
            <div className=' col-12 intro'>
                <p className="text-justify">
                    {textData.description}
                </p>
            </div>

        </div>
    );
}

export default Text;
