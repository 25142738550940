import React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FooterImage from '../../assets/footerlogo.png';
import './footer.css';

function Footer() {
  return (
    <div className='footer container-fluid'>
      <div className='row row7'>
        <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className='socialMedia'>
            <img src={FooterImage} alt="Footer Logo"></img>
          </div>
        </div>
        <div className='col-lg-4 col-md-6 col-sm-12 mb-4 text-center'>
          <MailOutlineIcon className="mx-2" />
          <FacebookIcon className="mx-2" />
          <InstagramIcon className="mx-2" />
          <WhatsAppIcon className="mx-2" />
        </div>
        <div className='col-lg-4 col-md-6 col-sm-12 mb-4 text-center'>
          <div className='address'>

            <p >
              Selmans Network
              Jakov Xoxa, St. Blini 2, Pristina, Kosova
              info@selmans.net   |  +383 49 544 544
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
