import React from 'react';
import './Loading.css';

const Loading = () => {
    return (
        <div className="loading-overlay">

            <div className="loader">
                <div className="box"></div>
                <div className="box"></div>
                <div className="box"></div>
            </div>

        </div>
    );
}

export default Loading;
