import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Pictures.css';


const baseURL = "https://adminsn.laratest-app.com/api/services";

const Pictures = () => {


    const [serviceData, setServiceData] = useState(null);

    const navigate = useNavigate();


    const handleClick = (title) => {
        navigate(`/service/${title}`);
    }



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(baseURL);
                const serviceInfo = response.data.services;
                setServiceData(serviceInfo);
            } catch (error) {
                console.error('Error fetching service data: ', error);
            }
        };

        fetchData();
    }, []);

    if (!serviceData) return null;

    const renderColumns = () => {
        const maxColumns = 4;
        const rows = [];

        for (let i = 0; i < serviceData.length; i += maxColumns) {
            const row = serviceData.slice(i, i + maxColumns);
            rows.push(
                <div className="row row3" key={i}>
                    {row.map(item => (

                        <div className='network col-lg-3 col-md-4 col-sm-6 mb-3' onClick={() => handleClick(item.title)}>
                            <h2>{item.title}</h2>
                            {/* <img src={`https://adminsn.laratest-app.com/storage/image/${item.cover}`} className="img-fluid" alt=""></img> */}
                            <img src={`https://adminsn.laratest-app.com/storage/${item.cover}`} className="img-fluid" alt={item.title}></img>

                            <p>{item.short_description}</p>
                        </div>
                    ))}
                </div>
            );
        }

        return rows;
    };

    return (
        <div >
            {renderColumns()}
        </div>
    );
}


export default Pictures;
